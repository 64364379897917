import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.palette.backgroundBlack.main,
      position: 'relative',
      padding: '12px 0px 21px 0px',
      [theme.breakpoints.up('sm')]: {
        padding: '18px 0px 14px 0px',
      },
      [theme.breakpoints.up('lg')]: {
        padding: '18px 0px 13px 0px',
      },

      '&$blurContent': {
        filter: 'blur(4px)',
      },

      '&$invert': {
        backgroundColor: theme.palette.common.white,
      },
    },

    header: {
      paddingBottom: 0,
      [theme.breakpoints.up('sm')]: {
        paddingBottom: 7,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid ',
        borderBottomColor: theme.palette.beige.main,
      },
      [theme.breakpoints.up('lg')]: {
        paddingBottom: 16,
      },
    },

    logo: {
      position: 'relative',
      left: -17,
      width: 242,
      height: 54,
      [theme.breakpoints.up('sm')]: {
        left: -19,
        width: 269,
        height: 60,
      },
      [theme.breakpoints.up('lg')]: {
        left: -23,
        width: 322,
        height: 72,
      },
    },

    scrollUp: {
      padding: 0,
      position: 'absolute',
      bottom: 40,
      right: 36,
      zIndex: 10,
      borderRadius: 40,
      width: 40,
      height: 40,
      borderWidth: 1,
      borderStyle: 'solid',
      // borderColor: theme.palette.common.white,
      '& span': {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        backgroundColor: 'transparent',
        width: '100%',
      },
      '& svg path': {
        fill: theme.palette.common.white,
      },
      '&:hover': {
        backgroundColor: `${theme.palette.beige.main} !important`,
        borderColor: `${theme.palette.beige.main} !important`,
        '& svg path': {
          fill: theme.palette.common.black,
        },
      },
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        position: 'relative',
        top: 1,
        bottom: 'unset',
        right: 'unset',
        width: 40,
        height: 40,
      },
      [theme.breakpoints.up('lg')]: {
        width: 40,
        height: 40,
      },

      '&$invert': {
        borderColor: theme.palette.common.black,
        fill: theme.palette.common.black,
        '& svg path': {
          fill: theme.palette.common.black,
        },

        '&:hover': {
          backgroundColor: `${theme.palette.common.black} !important`,
          borderColor: `${theme.palette.common.black} !important`,
          '& svg path': {
            fill: theme.palette.beige.main,
          },
        },
      },
    },

    scrollUpIcon: {
      width: 32,
      height: 32,
      [theme.breakpoints.up('lg')]: {
        width: 40,
        height: 40,
      },
    },

    content: {
      marginTop: theme.spacing(1.63),
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: -1,
      },
    },

    topics: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      padding: 0,
      '&.topic0': {
        gridArea: 'a',
      },
      '&.topic1': {
        gridArea: 'b',
      },
      '&.topic2': {
        gridArea: 'c',
      },
      '& $socials': {
        gridArea: 'socials',
      },
      [theme.breakpoints.up('sm')]: {
        display: 'grid',
        gridTemplateAreas: `
          "a b"
          "c socials"
        `,
        gridTemplateColumns: '33.333333% 33.333333%',
        '& $socials': {
          '& $topicLabel': {
            borderLeftWidth: 1,
            paddingLeft: 15,
          },
        },
        '& .topic1': {
          '& $topicLabel': {
            borderLeftWidth: 1,
            paddingLeft: 15,
          },
          '& $topicItem': {
            paddingLeft: 9,
          },
        },
      },
      [theme.breakpoints.up('lg')]: {
        '& $socials': {
          '& $topicLabel': {
            paddingLeft: 37,
          },
        },
        '& .topic1': {
          '& $topicLabel': {
            paddingLeft: 37,
          },
          '& $topicItem': {
            paddingLeft: 19,
          },
        },
      },
    },
    topic: {
      display: 'inline-block',
      padding: 0,
      marginBottom: theme.spacing(3.5),
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(4.5),
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: theme.spacing(5.3),
      },
    },
    topicLabel: {
      display: 'inline-block',
      width: '100%',
      fontSize: 12,
      lineHeight: 1.5,
      letterSpacing: 0.2,
      textTransform: 'uppercase',
      color: theme.palette.gray.main,
      borderWidth: '1px 0',
      borderColor: theme.palette.beige.main,
      borderStyle: 'solid',
      padding: '17px 0 16.8px 0',
      marginBottom: 3,
      [theme.breakpoints.up('sm')]: {
        padding: '17px 0 14.9px 0',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 14,
        lineHeight: '20px',
        padding: '15px 0 14.4px 0',
        letterSpacing: 0,
      },

      '&$invert': {
        color: theme.palette.gray.dark,
        borderColor: theme.palette.beige.main,
      },
    },
    topicItems: {},
    topicItem: {
      padding: 0,
      marginTop: 13.5,
      [theme.breakpoints.up('lg')]: {
        marginTop: 16.4,
      },
    },
    topicItemLink: {
      fontSize: 16,
      cursor: 'pointer',
      color: theme.palette.common.white,
      position: 'relative',

      '&:after': {
        content: "''",
        width: '100%',
        height: 0,
        position: 'absolute',
        display: 'block',
        background: 'transparent',
        transition: 'height .15s ease-in-out, background-color .15s ease-in-out',
      },

      '&:hover': {
        '&:after': {
          height: 2,
          background: theme.palette.beigeMedium.main,
        },
      },

      '&$invert': {
        color: theme.palette.common.black,
      },
    },

    socials: {},
    socialsRoot: {
      marginTop: theme.spacing(2.5),
      padding: 0,
      justifyContent: 'flex-start',
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(1.7),
        paddingLeft: 15,
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 37,
      },
      '& li': {
        // margin: theme.spacing(0.75, 0),
      },

      '& li + li': {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('lg')]: {
          marginLeft: theme.spacing(2.5),
        },
      },
    },
    socialItem: {
      padding: 0,
      marginTop: 0,
      marginBottom: 0,
    },

    footer: {
      display: 'flex',
      borderTop: '1px solid ',
      borderColor: theme.palette.beige.main,
      padding: '16px 0 25px 0',
    },

    metas: {
      padding: 0,
      [theme.breakpoints.up('sm')]: {
        width: '100%',
      },
    },

    meta: {
      display: 'flex',
      flexDirection: 'column-reverse',
      padding: 0,
      alignItems: 'flex-start',
      rowGap: 33,
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        rowGap: 0,
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },

    metaLabel: {
      fontSize: 12,
      lineHeight: '18px',
      color: theme.palette.common.white,
      [theme.breakpoints.up('lg')]: {
        fontSize: 14,
        lineHeight: '20px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 14,
      },

      '&$invert': {
        color: theme.palette.common.black,
      },
    },

    metaItem: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: '1.4px 0',
      columnGap: 21,
      rowGap: 18,
      [theme.breakpoints.up('sm')]: {
        rowGap: 0,
        columnGap: 6.7,
      },
      [theme.breakpoints.up('lg')]: {
        columnGap: 30,
      },
    },

    metaItemLinkWrap: {
      width: 'unset',
      padding: 0,
    },

    metaItemLink: {
      fontSize: 12,
      lineHeight: '18px',
      color: theme.palette.common.white,
      [theme.breakpoints.up('lg')]: {
        fontSize: 14,
        lineHeight: '20px',
      },

      '&$invert': {
        color: theme.palette.common.black,
      },
    },

    blurContent: {},

    invert: {},
  }),
  { name: 'Footer' }
)
