import React, { useState, useRef } from 'react'
import { useRouter } from 'next/router'
import { Container, List, Backdrop, Collapse, useTheme, useMediaQuery, ClickAwayListener } from '@material-ui/core'

import Link from 'next/link'

import clsx from 'clsx'

import { MenuItem as MenuItemType, BaseMenuItem } from '../../../@types/Menu'
import { NavMetaSettings } from '../../../@types/PageSettings'

import LogoWhite from '../../../public/logo/logo-white.svg'
import LogoBlack from '../../../public/logo/logo-black.svg'

import Socials from '../Socials'

import NestedItems from './components/NestedItems'
import MenuItem from './components/MenuItem'
import Hamburger from './components/Hamburger'
import DocFinder from './components/DocFinder'

import useStyles from './MainMenu.styles'

type Props = {
  data: MenuItemType[]
  socials: BaseMenuItem[]
  inverted?: boolean
  isHidden: boolean
  onToggleMenu?: (open: boolean) => void
  parentRef?: React.MutableRefObject<HTMLDivElement | null>
  navMeta?: NavMetaSettings
}

const MainMenu: React.FC<Props> = ({ data, socials, inverted = false, onToggleMenu = () => false, navMeta }) => {
  const classes = useStyles()

  const router = useRouter()

  const slug = (router?.query?.slug as []) || []
  const currentPath = ['', ...slug, ''].join('/')

  const theme = useTheme()

  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))

  const collapseWrapRef = useRef<HTMLDivElement | null>(null)

  const [activeNestedItems, setActiveNestedItems] = useState<string>('') // this is the label passed to the nested items
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false)
  const [backDropVisible, setBackDopVisible] = useState<boolean>(false)

  const closeAll = () => {
    if (activeNestedItems || backDropVisible || mobileMenuOpen) {
      setActiveNestedItems('')
      onToggleMenu(false)
      setMobileMenuOpen(false)
      setBackDopVisible(false)
    }
  }

  const handleNestedItemToggle = (
    label: string,
    labelRef?: React.MutableRefObject<HTMLDivElement | null>,
    url?: string
  ) => {
    if (!label && !labelRef && !url) return

    if (activeNestedItems && !label) {
      setActiveNestedItems('')

      return setTimeout(() => {
        if (isLgUp) {
          setBackDopVisible(false)
          onToggleMenu(false)
        }
      }, 200)
    }

    if (url) return

    setBackDopVisible(true)
    onToggleMenu(true)

    setTimeout(() => {
      setActiveNestedItems(label)

      labelRef && adjustFocus(labelRef)
    }, 200)
  }

  const handleMobileMenuToggle = () => {
    if (mobileMenuOpen) {
      setMobileMenuOpen(false)

      return setTimeout(() => {
        setBackDopVisible(false)
        onToggleMenu(false)
      }, 200)
    }

    setBackDopVisible(true)
    onToggleMenu(true)

    setTimeout(() => {
      setMobileMenuOpen(true)
    }, 200)
  }

  const adjustFocus = (labelRef?: React.MutableRefObject<HTMLDivElement | null>) => {
    if (isLgUp) return

    setTimeout(() => {
      requestAnimationFrame(() => {
        const labelRect = labelRef?.current?.getBoundingClientRect?.()

        if (labelRect) {
          const topPosition = labelRect?.top < 0 ? 0 : labelRect?.top

          const mobileMenuHeadingHeight = 58

          collapseWrapRef?.current?.scroll({
            top: collapseWrapRef?.current?.scrollTop + topPosition - mobileMenuHeadingHeight,
            behavior: 'smooth',
          })
        }
      })
    }, 300)
  }

  const activeBackdrop = mobileMenuOpen || backDropVisible

  const invertColors = inverted || activeBackdrop

  const socialData = (socials && socials?.length > 0 && socials[0]) || { items: [] }

  const handleNavItemClick = (labelRef?: React.MutableRefObject<HTMLDivElement | null>, url?: string) => {
    if (isLgUp) return handleNestedItemToggle('', undefined, url)

    return handleMobileMenuToggle()
  }

  const handleDocFinderSearch = () => handleNavItemClick()

  return (
    <Container>
      <div
        className={clsx(classes.root, {
          [classes.inverted]: invertColors,
          [classes.mobileNavOpen]: activeBackdrop,
        })}
      >
        <div className={classes.heading}>
          <Link href="/">
            <div className={classes.logo}>{invertColors ? <LogoWhite /> : <LogoBlack />}</div>
          </Link>

          {isLgUp && <DocFinder link={navMeta?.search} inverted={invertColors} onSearch={handleDocFinderSearch} />}

          <Hamburger
            active={mobileMenuOpen}
            inverted={invertColors}
            className={classes.hamburgerIcon}
            onClick={handleMobileMenuToggle}
          />
        </div>

        <div ref={collapseWrapRef} className={clsx(classes.collapseWrap, { [classes.collapsed]: mobileMenuOpen })}>
          <Collapse in={mobileMenuOpen} timeout={200} className={classes.collapse}>
            {!isLgUp && mobileMenuOpen && (
              <DocFinder link={navMeta?.search} inverted={invertColors} onSearch={handleDocFinderSearch} />
            )}

            <ClickAwayListener onClickAway={isLgUp ? closeAll : () => false}>
              <List className={classes.items} component="nav">
                {data.map((item) => {
                  const hasNestedItems = item?.items && item.items.length > 0
                  const isOpen = activeNestedItems === item.label
                  return hasNestedItems ? (
                    <NestedItems
                      key={item.label}
                      label={item.label}
                      items={item.items || []}
                      inverted={invertColors}
                      onToggle={handleNestedItemToggle}
                      open={isOpen}
                      fade={Boolean(isLgUp && activeNestedItems && !isOpen)}
                      onItemClick={handleNavItemClick}
                      socials={isLgUp ? socialData.items : undefined}
                      currentPath={currentPath}
                    />
                  ) : (
                    <MenuItem
                      key={item.label}
                      label={item.label}
                      url={item.target}
                      inverted={invertColors}
                      fade={Boolean(isLgUp && activeNestedItems)}
                      onClick={handleNavItemClick}
                      active={currentPath === item?.target}
                      linkType={item.type}
                    />
                  )
                })}

                {navMeta?.link && (
                  <div className={classes.last}>
                    <div className={classes.divider} />
                    <MenuItem
                      label={navMeta?.link.label || ''}
                      fixed
                      inverted={invertColors}
                      url={navMeta?.link.target}
                      onClick={handleNavItemClick}
                      active={currentPath === navMeta?.link.target}
                      fade={Boolean(isLgUp && activeNestedItems)}
                    />
                  </div>
                )}
              </List>
            </ClickAwayListener>

            {!isLgUp && <Socials data={socialData.items} />}
          </Collapse>
        </div>

        <Backdrop
          open={activeBackdrop}
          classes={{ root: classes.backdrop }}
          transitionDuration={{ appear: 500, exit: 500, enter: 500 }}
        />
      </div>
    </Container>
  )
}

export default MainMenu
