import React, { useEffect, useState, useRef } from 'react'

import clsx from 'clsx'

import Head from 'next/head'

import { Slide, useScrollTrigger, AppBar, CssBaseline } from '@material-ui/core'

import browserClassNames from '../../helpers/browserClassNames'

import { PageProps, PageType } from '../../../@types/Page'
import { MenuItem } from '../../../@types/Menu'

import MainMenu from '../../components/MainMenu'

import FloatingActionButton from '../../components/FloatingActionButton'

import Footer from '../../components/Footer'

import useStyles from './Layout.styles'
import WelcomeDialog from '../../components/WelcomeDialog'

function HideOnScroll(props: { children: React.ReactElement; window?: any; forceShow?: boolean }) {
  const { children, window, forceShow } = props

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  })

  const visible = forceShow || !trigger

  useEffect(() => {
    if (visible) {
      document.body.classList.add('menuVisible')
    } else {
      document.body.classList.remove('menuVisible')
    }
  }, [visible])

  return (
    <Slide appear={false} direction="down" in={visible}>
      {children}
    </Slide>
  )
}

interface Props extends PageProps {
  children: React.ReactNode
  invertMenuColors?: boolean
}

const Layout: React.FC<Props> = ({ settings, content, children, invertMenuColors = false }) => {
  const classes = useStyles()

  const menuWrapperRef = useRef<HTMLDivElement | null>(null)

  const [navOpen, setNavOpen] = useState<boolean>(false)
  const [scrolledPosition, setScrolledPosition] = useState<number>(0)
  const [menuHeight, setMenuHeght] = useState<number>(0)
  const [browserClasses, setBrowserClasses] = useState<string[]>([])

  const [menuVisibleInitially, setMenuVisibleInitially] = useState<boolean>(() => {
    if (typeof window !== 'undefined' && window?.location?.hash) return true

    return false
  })

  const handleToggleMenu = (open: boolean) => {
    setNavOpen(open)
  }

  const listenToScroll = () => {
    const positionY = document.body.scrollTop || document.documentElement.scrollTop

    setScrolledPosition(positionY)
  }

  const resetInitialMenuVisibility = () => {
    setMenuVisibleInitially(false)
  }

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll)
    setMenuHeght(menuWrapperRef?.current?.clientHeight || 0)

    setBrowserClasses(browserClassNames())

    return () => window.removeEventListener('scroll', listenToScroll)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (menuVisibleInitially) {
        window?.addEventListener('scroll', resetInitialMenuVisibility)
      }
    }, 3000)

    return () => window?.removeEventListener('scroll', resetInitialMenuVisibility)
  }, [])

  const colorTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 450,
  })

  const removeMenuBg = content?.type === PageType.Home || content?.type === PageType.Campaign
  /**
   * This is a generic one, that will fully invert the page
   */
  const invertPageColors = content.type === PageType.Campaign

  /**
   * This will invert only a component that has to be inverted given below conditions
   */
  const invertColors = !colorTrigger && invertMenuColors

  return (
    <React.Fragment>
      <CssBaseline />

      <Head>
        <title>{content?.title}</title>

        {content?.canonical && <link rel="canonical" href={content?.canonical} />}

        {Object.keys(content?.meta.tags || {}).map((key) => {
          const value = content?.meta.tags[key] as string
          return <meta key={key} name={key} content={value} />
        })}
        {content?.meta.schema && content?.meta.schema != '' && (
          <>
            <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: content?.meta.schema }}></script>
          </>
        )}

        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              'function detectIE(){try{return!!!!document.documentMode||navigator.userAgent.indexOf("MSIE")>0}catch(e){var t=e.toString();console.log(t)}}detectIE()&&(window.location="/assets/outdated.html");',
          }}
        ></script>

        {process.env.NEXT_PUBLIC_APP_ENV !== 'development' && (
          <script
            src="https://cloud.ccm19.de/app.js?apiKey=f30e2a55ccd84541024e91bf9fe3fd9d7dbe89295d4f95d8&amp;domain=65f0238aa79f26205f01b592&amp;lang=de_DE"
            referrerPolicy="origin"
          ></script>
        )}
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
              var _mtm = window._mtm = window._mtm || [];_mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
              (function() {
                var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                g.async=true; g.src='https://cdn.matomo.cloud/merzaestheticsemea.matomo.cloud/container_zcB3nGKu.js'; s.parentNode.insertBefore(g,s);
              })();
            `,
          }}
        ></script>
      </Head>

      <div
        className={clsx(
          classes.root,
          { [classes.nonScrollable]: navOpen, [classes.inverted]: invertPageColors },
          browserClasses
        )}
      >
        <WelcomeDialog />

        <HideOnScroll forceShow={menuVisibleInitially}>
          <AppBar
            className={clsx(classes.menuScrollWrap, {
              [classes.menuBlack]: invertPageColors,
              [classes.menuWhite]: !invertPageColors,
              [classes.menuScrollWrapNoBackground]: !colorTrigger && removeMenuBg,
            })}
          >
            <div
              id="main_layout"
              ref={menuWrapperRef}
              className={clsx({ [classes.mainMenuWrapper]: navOpen, [classes.navOpen]: navOpen })}
            >
              <MainMenu
                data={(settings?.navigation as MenuItem[]) || []}
                onToggleMenu={handleToggleMenu}
                inverted={invertPageColors || invertColors}
                isHidden={false}
                socials={settings?.social || []}
                parentRef={menuWrapperRef}
                navMeta={settings?.navMeta}
              />
            </div>
          </AppBar>
        </HideOnScroll>

        <div className={clsx(classes.content, { [classes.navOpen]: navOpen })}>{children}</div>
        {content?.['has-sticky_doc_search'] && <FloatingActionButton invertColors={invertPageColors} />}

        <Footer
          blur={navOpen}
          topics={(settings?.footer as MenuItem[]) || []}
          social={settings?.social || []}
          meta={settings?.meta || []}
          invert={invertPageColors}
        />
      </div>
    </React.Fragment>
  )
}

export default Layout
