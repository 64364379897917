import { Theme, makeStyles } from '@material-ui/core'

export default makeStyles<Theme, { size: number }>(
  (theme) => ({
    root: {
      width: 20,
      height: 20,
      margin: 5,
      position: 'relative',
      webkitTransform: 'rotate(0deg)',
      mozTransform: 'rotate(0deg)',
      oTransform: 'rotate(0deg)',
      transform: 'rotate(0deg)',
      webkitTransition: '.5s ease-in-out',
      mozTransition: '.5s ease-in-out',
      oTransition: '.5s ease-in-out',
      transition: '.5s ease-in-out',
      cursor: 'pointer',

      '&$inverted span': {
        background: theme.palette.common.white,
      },

      '& span': {
        display: 'block',
        position: 'absolute',
        height: 2,
        width: '100%',
        background: theme.palette.common.black,
        opacity: 1,
        left: 0,
        webkitTransform: 'rotate(0deg)',
        mozTransform: 'rotate(0deg)',
        oTransform: 'rotate(0deg)',
        transform: 'rotate(0deg)',
        webkitTransition: '.25s ease-in-out',
        mozTransition: '.25s ease-in-out',
        oTransition: '.25s ease-in-out',
        transition: '.25s ease-in-out',

        '&:nth-child(1)': {
          top: 2,
        },

        '&:nth-child(2), &:nth-child(3)': {
          top: 9,
        },

        '&:nth-child(4)': {
          top: 16,
        },
      },

      '&$open span': {
        '&:nth-child(1)': {
          top: 9,
          width: '0%',
          left: '50%',
        },

        '&:nth-child(2)': {
          webkitTransform: 'rotate(45deg)',
          mozTransform: 'rotate(45deg)',
          oTransform: 'rotate(45deg)',
          transform: 'rotate(45deg)',
        },

        '&:nth-child(3)': {
          webkitTransform: 'rotate(-45deg)',
          mozTransform: 'rotate(-45deg)',
          oTransform: 'rotate(-45deg)',
          transform: 'rotate(-45deg)',
        },

        '&:nth-child(4)': {
          top: 9,
          width: '0%',
          left: '50%',
        },
      },
    },
    open: {},
    inverted: {},
  }),
  { name: 'Hamburger' }
)
