import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {
      [theme.breakpoints.up('lg')]: {
        paddingLeft: theme.spacing(2),
      },

      '&$open': {
        paddingBottom: 0,

        [theme.breakpoints.up('lg')]: {
          paddingBottom: 0,
          borderWidth: 0,
        },
      },

      '& .nested-icon-right': {
        display: 'none',

        [theme.breakpoints.up('lg')]: {
          display: 'inline-flex',
          width: 40,
          height: 40,
          fill: theme.palette.common.white,
          alignSelf: 'flex-start',
          marginTop: 10,
          marginLeft: theme.spacing(2),
          opacity: 0,
          transition: theme.transitions.create(['opacity', 'margin'], {
            duration: theme.transitions.duration.standard,
          }),
        },
      },
    },
    collapse: {
      [theme.breakpoints.up('lg')]: {
        position: 'absolute',
        top: 78,
        transitionDuration: '0ms !important',
        width: '100%',
        height: '100vh',
        '& .MuiCollapse-wrapper': {
          height: `calc(100vh - 200px)`,
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
        },
        '& .MuiCollapse-wrapperInner': {
          overflowY: 'scroll',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
        },
        '& .MuiCollapse-wrapperInner::-webkit-scrollbar ': {
          display: 'none',
          ['@media (max-height:675px)']: {
            display: 'none',
          },
        },
      },
    },

    expandIcon: {
      fill: theme.palette.common.black,
      width: 30,
      height: 30,
      margin: '0px 2px 0px 2px',
      transform: 'rotate(-180deg)',
      transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.short,
      }),

      [theme.breakpoints.up('lg')]: {
        width: 32,
        height: 32,
      },

      '&$inverted': {
        fill: theme.palette.common.white,
      },

      '&$open': {
        transform: 'rotate(0)',
      },
    },
    inverted: {},
    open: {},
    nestedIndicator: {
      color: theme.palette.common.white,
      lineHeight: 1.67,
      fontSize: 12,

      [theme.breakpoints.up('sm')]: {
        marginTop: 10,
        marginRight: theme.spacing(1.5),
        marginLeft: theme.spacing(4),
      },

      [theme.breakpoints.up('lg')]: {
        alignSelf: 'flex-start',
        marginRight: theme.spacing(4.75),
        marginLeft: theme.spacing(5.25),
        fontSize: 14,
        lineHeight: 1.43,
      },
    },
    animatedEntry: {},
    fade: {
      opacity: 0.4,

      '&:hover': {
        opacity: 1,
      },
    },

    socialsWrap: {
      position: 'absolute',
      bottom: 'calc(0px + 1vh)',
      right: 0,
    },
  }),
  { name: 'NestedItems' }
)
