import React from 'react'

import clsx from 'clsx'

import useStyles from './Hamburger.styles'

type Props = {
  active?: boolean
  className?: string
  inverted?: boolean
  size?: number
  onClick?: () => void
}

const Hamburger: React.FC<Props> = ({ active = false, className, inverted = false, size = 20, onClick }) => {
  const classes = useStyles({ size })

  const handleClick = () => {
    onClick && onClick()
  }

  return (
    <div
      className={clsx(classes.root, className, { [classes.open]: active, [classes.inverted]: inverted })}
      onClick={handleClick}
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}

export default Hamburger
