import { detect } from 'detect-browser'

const browser = detect()

const browserClassNames = (): string[] => {
  if (typeof window === 'undefined') return []

  const { name = '', version = '', os = '' } = browser || {}

  const browserName = name.toLocaleLowerCase()
  const osName = os?.toLocaleLowerCase()?.replace(/ /g, '-') || ''
  const versionParts = version?.split('.') || []
  const majorVersion = versionParts?.[0] || ''
  const fullVersion = versionParts?.join('-') || ''
  const mobileOs =
    os === 'iOS' || os === 'BlackBerry OS' || os === 'Android OS' || os === 'android' || os === 'Windows Mobile'

  return [
    `browser-${browserName}`,
    `browser-os-${osName}`,
    `browser-${browserName}-${majorVersion}`,
    `browser-${browserName}-${fullVersion}`,
    mobileOs ? `browser-os-mobile` : '',
  ]
}

export default browserClassNames
