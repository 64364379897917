export enum MotomoLinkTrackingPosition {
  BODY = 'body',
  FOOTER = 'footer',
  HEADER = 'header',
  POPUP = 'popup',
}

type Options = {
  position: MotomoLinkTrackingPosition
  label: string
  href: string
  clickHandler?: any
  isCta?: boolean
}

const useMatomoLinkTracking = (): [(options: Options) => (event: any) => void] => {
  const trackClicks =
    ({ position, label, href, clickHandler = () => false, isCta = false }: Options) =>
    (event: React.MouseEvent<React.ReactElement>) => {
      if (window) {
        window._mtm = window._mtm || []

        const event = isCta ? 'button_click' : 'link_click'

        window._mtm.push({
          event,
          category: `${event}_${position}`,
          action: label,
          label: href,
        })
      }

      clickHandler(event)
    }

  return [trackClicks]
}

export default useMatomoLinkTracking
