import React, { useState } from 'react'
import { useRouter } from 'next/router'
import clsx from 'clsx'

import { Typography, InputBase, IconButton, useTheme, useMediaQuery } from '@material-ui/core'

import Link from 'next/link'

import { LinkTarget } from '../../../../../@types/Fields'

import IconArrow from '../../../../../public/icons/icon-arrow.svg'
import IconDoc from '../../../../../public/icons/icon-doc-search.svg'

import useStyles from './DocFiner.styles'
import useMatomoDocSearchTracking from '../../../../hooks/useMatomoDocSearchTracking'

type Props = {
  link?: LinkTarget
  inverted?: boolean
  onSearch?: () => void
}

const DocFinder: React.FC<Props> = ({ link, inverted = false, onSearch = () => false }) => {
  const theme = useTheme()

  const router = useRouter()

  const isUpSm = useMediaQuery(theme.breakpoints.up('sm'))
  const isUpLg = useMediaQuery(theme.breakpoints.up('lg'))

  const classes = useStyles()

  const [hovered, setHovered] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState<string>('')

  const { trackDocSearch } = useMatomoDocSearchTracking()

  const handleMouseEnter = () => isUpLg && setHovered(true)

  const handleMouseLeave = () => isUpLg && setHovered(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e?.target?.value)

  const handleClick = () => {
    onSearch()

    trackDocSearch(searchTerm)

    router.push({
      pathname: '/arzt-suche/',
      query: {
        search: searchTerm,
      },
      hash: searchTerm ? 'map' : 'map-filters',
    })
  }

  const DocIcon = React.forwardRef(() => (
    <IconDoc className={clsx(classes.icon)} fill={theme.palette.common[inverted ? 'white' : 'black']} />
  ))

  return (
    <div className={classes.container} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className={classes.iconWrapper}>
        <Link href={link?.target || ''}>
          <DocIcon />
        </Link>
        <Typography variant="body1" className={clsx(classes.text, { inverted, [classes.textAtHover]: hovered })}>
          {link?.label}
        </Typography>
      </div>
      {!isUpLg && (
        <>
          <Typography variant="body1" className={clsx(classes.mobileText, { inverted })}>
            Finden Sie den passenden Arzt in Ihrer Nähe.
          </Typography>
          <div />
        </>
      )}
      <div
        className={clsx(classes.searchRow, {
          [classes.searchRowAtHover]: hovered,
          [classes.searchRowVisible]: !isUpLg,
        })}
      >
        <InputBase
          fullWidth
          onChange={handleChange}
          classes={{
            root: clsx(classes.inputRoot, { [classes.inputRootInverted]: inverted }),
            input: clsx(classes.input, { [classes.inputInverted]: inverted }),
          }}
          placeholder="PLZ oder Ort"
          autoFocus={false}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleClick()
            }
          }}
        />
        <IconButton className={clsx(classes.button, { [classes.buttonInverted]: inverted })} onClick={handleClick}>
          <IconArrow width={32} height={32} />
        </IconButton>
      </div>
    </div>
  )
}

export default DocFinder
