const useMatomoDocSearchTracking = (): {
  trackDocSearch: (searchInput: string) => void
} => {
  const trackDocSearch = (searchInput: string) => {
    if (window) {
      window._mtm = window._mtm || []
      window._mtm.push({
        event: 'docsearch',
        category: 'docsearch',
        action: 'search',
        label: searchInput,
      })
    }
  }

  return { trackDocSearch }
}

export default useMatomoDocSearchTracking
