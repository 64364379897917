import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 24,
      [theme.breakpoints.up('sm')]: {
        paddingTop: 38,
        display: 'grid',
        gridTemplateAreas: `
          "1fr 1fr"
          "1fr 1fr"
        `,
        alignItems: 'center',
      },
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingTop: 0,
      },
    },

    icon: {
      width: 30,
      height: 30,
      marginRight: theme.spacing(1),

      [theme.breakpoints.up('sm')]: {
        width: 35,
        height: 35,
      },

      [theme.breakpoints.up('lg')]: {
        width: 40,
        height: 40,
      },
    },

    text: {
      fontFamily: 'ProximaNova-Regular',
      fontSize: 16,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 0.5,
      whiteSpace: 'nowrap',
      width: 140,
      opacity: 1,
      transition: ' width .5s, opacity .2s ease-in .2s',
      color: theme.palette.common.black,
    },

    textAtHover: {
      width: 0,
      opacity: 0,
      transition: 'width .5s, opacity .2s ease-out',
    },

    searchRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: 0,
      opacity: 0,
      transition: 'width .5s, opacity .2s ease-out',

      marginBottom: 40,
      marginTop: 18,
      [theme.breakpoints.up('sm')]: {
        marginTop: 24,
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: 0,
        marginTop: 0,
      },
    },

    searchRowAtHover: {
      width: 256,
      opacity: 1,
      transition: 'width .5s, opacity .2s ease-in .2s',
    },

    searchRowVisible: {
      width: '100%',
      opacity: 1,
    },

    inputRoot: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.common.black,
      maxWidth: 220,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 278,
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 220,
      },
    },

    inputRootInverted: {
      borderColor: theme.palette.common.white,
    },

    input: {
      fontSize: 16,
      padding: '10px 13px',
      letterSpacing: 'normal',
      color: theme.palette.common.black,
      [theme.breakpoints.up('lg')]: {
        fontSize: 12,
        padding: 13,
      },

      '&::placeholder': {
        opacity: 1,
      },
    },

    inputInverted: {
      color: theme.palette.common.white,
    },

    button: {
      width: 40,
      height: 40,
      backgroundColor: theme.palette.common.black,
      borderRadius: 40,
      padding: 3,
      borderWidth: 1,
      borderColor: theme.palette.common.black,
      borderStyle: 'solid',
      marginLeft: theme.spacing(2),

      '&:hover': {
        backgroundColor: 'transparent',
      },

      '&:hover path': {
        fill: theme.palette.common.black,
      },

      '& path': {
        fill: theme.palette.common.white,
      },
    },

    buttonInverted: {
      borderColor: theme.palette.common.white,
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&:hover path': {
        fill: theme.palette.common.white,
      },
      '& path': {
        fill: theme.palette.common.black,
      },
    },

    mobileText: {
      fontFamily: 'ProximaNova-Regular',
      fontSize: 16,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 0.5,
      paddingTop: 18,
      [theme.breakpoints.up('sm')]: {
        paddingTop: 0,
      },
    },

    iconWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  }),
  {
    name: 'MainMenuDocFinder',
  }
)
