import React from 'react'

import clsx from 'clsx'

import { List, ListItem, Link } from '@material-ui/core'

import { MenuItem } from '../../../@types/Menu'

import IconLinkedin from '../../../public/icons/icon-linked-in.svg'
import IconFacebookWhite from '../../../public/icons/mmf-icon-facebook-white.svg'
import IconInstagramWhite from '../../../public/icons/mmf-icon-instagram-white.svg'
import IconYoutubeWhite from '../../../public/icons/mmf-icon-youtube-white.svg'
import IconPinterestWhite from '../../../public/icons/mmf-icon-pinterest-white.svg'

import useStyles from './Socials.styles'
import useMatomoLinkTracking, { MotomoLinkTrackingPosition } from '../../hooks/useMatomoLinkTracking'

type Props = {
  data?: MenuItem[]
  classes?: {
    root?: string
    item?: string
    link?: string
    icon?: string
  }
  invert?: boolean
}

const icons = {
  facebook: (className: string) => <IconFacebookWhite className={className} />,
  instagram: (className: string) => <IconInstagramWhite className={className} />,
  pinterest: (className: string) => <IconPinterestWhite className={className} />,
  linkedin: (className: string) => <IconLinkedin className={className} />,
  youtube: (className: string) => <IconYoutubeWhite className={className} />,
} as Record<string, (className: string) => React.ReactElement>

const Socials: React.FC<Props> = ({ data = [], classes: classesProp, invert }) => {
  const classes = useStyles()

  const [trackedLinkClick] = useMatomoLinkTracking()

  return (
    <List className={clsx(classes.root, classesProp?.root)}>
      {data?.map((item) => (
        <ListItem key={item.label} className={clsx(classes.item, classesProp?.item)}>
          <Link
            href={item?.target || item?.target_external}
            title={item.label}
            target="_blank"
            className={clsx(classes.link, classesProp?.link)}
            underline="none"
            onClick={trackedLinkClick({
              label: item.label,
              href: item?.target || item?.target_external || '',
              position: MotomoLinkTrackingPosition.BODY,
            })}
          >
            {icons[item.label.toLowerCase()](
              clsx(classes.icon, classesProp?.icon, item.label.toLowerCase(), { [classes.iconInvert]: invert })
            )}
          </Link>
        </ListItem>
      ))}
    </List>
  )
}

export default Socials
