import React, { useMemo } from 'react'

import { Collapse, List, Typography } from '@material-ui/core'

import clsx from 'clsx'

import { MenuItem as MenuItemType } from '../../../../../@types/Menu'

import IconExpand from '../../../../../public/icons/icon-expand.svg'
import IconArrow from '../../../../../public/icons/icon-arrow.svg'

import padNumber from '../../../../helpers/padNumber'

import Socials from '../../../Socials'

import MenuItem from '../MenuItem'

import useStyles from './NestedItems.styles'

type Props = {
  label: string
  items: MenuItemType[]
  open: boolean
  onToggle: (label: string, labelRef?: React.MutableRefObject<HTMLDivElement | null>) => void
  inverted?: boolean
  fade?: boolean
  onItemClick: () => void
  socials?: MenuItemType[]
  currentPath?: string
}

const NestedItems: React.FC<Props> = ({
  label,
  items,
  inverted = false,
  open,
  onToggle,
  fade = false,
  onItemClick = () => false,
  socials = [],
  currentPath = '',
}) => {
  const nestedAnimationDuration = 0.1

  const classes = useStyles()

  const handleToggle = (labelRef?: React.MutableRefObject<HTMLDivElement | null>) => {
    if (open) return onToggle('', labelRef)

    onToggle(label, labelRef)
  }

  const paths = useMemo(() => items?.map((item) => item?.target), [items])
  const isActive = paths.includes(currentPath)

  return (
    <>
      <MenuItem
        label={label}
        onClick={handleToggle}
        inverted={inverted}
        active={open || isActive}
        fade={fade}
        iconRight={
          <IconExpand className={clsx(classes.expandIcon, { [classes.inverted]: inverted, [classes.open]: open })} />
        }
      />

      <Collapse in={open} className={classes.collapse}>
        <List className={clsx(classes.root, { [classes.open]: open })} disablePadding>
          {items.map((item, index) => (
            <MenuItem
              onClick={onItemClick}
              key={`${item.label}_${index}`}
              label={item.label}
              url={item.target}
              info={item.info}
              inverted={inverted}
              iconLeft={
                <Typography component="span" className={classes.nestedIndicator}>
                  {padNumber(index + 1)}
                </Typography>
              }
              iconRight={<IconArrow className="nested-icon-right" />}
              style={{ transitionDelay: `${nestedAnimationDuration * index}s` }}
              enableAnimatedEntry={open}
              animatedEntry
              nested
              linkType={item.type}
            />
          ))}
        </List>

        <div className={classes.socialsWrap}>
          <Socials data={socials} />
        </div>
      </Collapse>
    </>
  )
}

export default NestedItems
