import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {
      height: '100%',
      paddingBottom: 50,
      [theme.breakpoints.up('sm')]: {},
    },

    drawerPaper: {
      overflow: 'visible',
      borderRadius: 10,
      backgroundColor: '#E2E1DD',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      [theme.breakpoints.up('lg')]: {
        height: 72,
        width: 389,
        top: `calc(100% - 129px)`,
        right: -35,
        borderRadius: 50,
      },

      '& .MuiBox-root': {
        borderRadius: 10,
        [theme.breakpoints.up('lg')]: {
          borderRadius: 50,
        },
      },
    },

    drawerWrap: {},

    show: {},

    hide: {
      '&.MuiDrawer-root > .MuiPaper-root': {
        transform: 'translateY(570px) translateY(-73.812px)!important',
      },
      [theme.breakpoints.up('lg')]: {
        '&.MuiDrawer-root > .MuiPaper-root': {
          transform: 'translateY(0px) translateX(683.188px)!important',
        },
      },
    },

    floatingBtn: {
      position: 'fixed',
      zIndex: 999,
      bottom: 50,
      right: 20,
      padding: 0,
      width: 80,
      height: 80,
      borderRadius: 40,

      [theme.breakpoints.up('sm')]: {
        width: 90,
        height: 90,
        borderRadius: 45,
      },
      [theme.breakpoints.up('lg')]: {
        width: 108,
        height: 108,
        borderRadius: 54,
      },

      // '& svg g:first-child > path:first-child': {
      //   opacity: 0,
      // },
      // '& svg g:first-child > path:nth-child(2)': {
      //   opacity: 0,
      // },
    },

    insideIcon: {
      marginRight: 5,
      [theme.breakpoints.up('sm')]: {
        marginRight: 15,
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: 5,
      },
    },

    wrap: {
      borderRadius: '10px!important',
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      [theme.breakpoints.up('lg')]: {
        position: 'absolute',
        top: 1,
        visibility: 'visible',
        right: 0,
        left: 0,
        padding: '0 20px',
      },
    },

    container: {
      maxWidth: 577,
      marginTop: 10,
      padding: '16px 15px 50px 15px',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        padding: '14px 3px 50px 2px',
      },
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
        padding: 0,
      },
    },

    closeBtnWrap: {
      minWidth: 'unset',
      width: 53,
      height: 53,
      backgroundColor: '#E2E1DD',
      position: 'absolute',
      top: -25.5,
      right: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 25,
      padding: 0,
      cursor: 'pointer',
      [theme.breakpoints.up('sm')]: {
        right: 35,
        top: -29.5,
        width: 60,
        height: 60,
        borderRadius: 30,
      },
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },

      '&:hover': {
        backgroundColor: '#E2E1DD',
      },
    },

    closeIcon: {
      width: 18,
    },

    title: {
      fontSize: 18,
      lineHeight: '26px',
      letterSpacing: '1px',
      fontWeight: 600,
      paddingTop: 5,
      [theme.breakpoints.up('sm')]: {
        fontSize: 20,
        lineHeight: '28px',
        letterSpacing: '1px',
        paddingTop: 7,
      },
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },

    titleWrap: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      marginBottom: theme.spacing(1.5),
    },

    btnWrap: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 16,
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        columnGap: 15,
      },
      [theme.breakpoints.up('lg')]: {
        position: 'relative',
      },
    },

    closeArrowWrap: {
      display: 'none',
      position: 'absolute',
      right: 1,
      top: 1,
      [theme.breakpoints.up('lg')]: {
        display: 'block',
        height: 48,
      },
    },

    closeArrow: {
      width: 32,
      position: 'relative',
      top: 1,
      left: 4,
    },

    input: {
      borderRadius: 0,
      fontSize: 12,
      lineHeight: '18px',
      letterSpacing: '0px',
      color: '#000',
      border: '1px solid #121212',
      height: 48,
      justifyContent: 'start',
      width: '100%',
      paddingLeft: 16,
      [theme.breakpoints.up('sm')]: {
        maxWidht: 338,
      },
      [theme.breakpoints.up('lg')]: {
        width: 204,
      },

      '& ::placeholder': {
        fontSize: 12,
        color: '#000',
        opacity: 1,
      },
    },

    searchButton: {
      borderRadius: 0,
      fontSize: 14,
      lineHeight: '18px',
      letterSpacing: 0.5,
      color: '#fff',
      backgroundColor: '#121212',
      border: 'none',
      height: 42,
      textAlign: 'center',
      width: '100%',
      textTransform: 'initial',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 220,
        height: 48,
      },
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },

    invert: {
      '& #Text-3': {
        '& path': {
          fill: '#fff',
        },
      },
    },
  }),
  { name: 'FloatingActionButton' }
)
