import React from 'react'
import { Button, ButtonProps } from '@material-ui/core'

import useStyles from './BaseButton.styles'
import clsx from 'clsx'

type Props = Omit<ButtonProps, 'type'> & {
  label: string
  onClick?: (event: any) => void
  type?: string
  fullWidth?: boolean
  classNames?: string
  disabled?: boolean
  target?: React.HTMLAttributeAnchorTarget
}

const BaseButton: React.FC<Props> = ({
  type = 'primary',
  label,
  onClick,
  classNames,
  fullWidth = false,
  disabled = false,
  ...rest
}) => {
  const styles = useStyles({ type })

  return (
    <Button
      fullWidth={fullWidth}
      variant="outlined"
      onClick={onClick}
      disabled={disabled}
      disableRipple={true}
      className={clsx(
        styles.button,
        {
          [styles.primaryButton]: type === 'primary',
          [styles.secondaryButton]: type === 'secondary',
        },
        classNames
      )}
      {...rest}
    >
      {label}
    </Button>
  )
}

export default BaseButton
