import React, { useRef } from 'react'

import NextLink from 'next/link'

import { ListItem, Link, Typography } from '@material-ui/core'

import clsx from 'clsx'

import useStyles from './MenuItem.styles'
import useMatomoLinkTracking, { MotomoLinkTrackingPosition } from '../../../../hooks/useMatomoLinkTracking'
import stripHtml from '../../../../helpers/stripHtml'
import { MenuItemType } from '../../../../../@types/Menu'

type Props = {
  label: string
  url?: string
  info?: string
  nested?: boolean
  active?: boolean
  inverted?: boolean
  animatedEntry?: boolean
  enableAnimatedEntry?: boolean
  fixed?: boolean
  onClick?: (ref: React.MutableRefObject<HTMLDivElement | null>, url: string | undefined) => void
  iconLeft?: React.ReactElement
  iconRight?: React.ReactElement
  style?: Record<string, unknown>
  fade?: boolean
  linkType?: MenuItemType
}

const MenuItem: React.FC<Props> = ({
  label,
  url,
  info,
  nested = false,
  active = false,
  inverted = false,
  animatedEntry = false,
  enableAnimatedEntry = false,
  fixed = false,
  onClick = () => false,
  iconLeft,
  iconRight,
  style,
  fade = false,
  linkType,
}) => {
  const labelRef = useRef<HTMLDivElement | null>(null)

  const [trackedLinkClick] = useMatomoLinkTracking()

  const classes = useStyles()

  const handleClick = () => {
    onClick(labelRef, url)
  }

  const linkTarget = linkType === 'external' ? '_blank' : undefined

  if (url) {
    return (
      <ListItem
        onClick={handleClick}
        disableGutters
        className={clsx(classes.root, {
          [classes.inverted]: inverted,
          [classes.active]: active,
          [classes.nested]: nested,
          [classes.animatedEntry]: animatedEntry,
          [classes.visible]: enableAnimatedEntry,
          [classes.fixed]: fixed,
          [classes.hasRightIcon]: iconRight,
          [classes.hasLeftIcon]: iconLeft,
          [classes.fade]: fade,
        })}
        style={style}
      >
        <NextLink href={url} passHref>
          <Link
            underline="none"
            onClick={trackedLinkClick({
              label: stripHtml(label),
              href: url,
              position: MotomoLinkTrackingPosition.HEADER,
            })}
            target={linkTarget}
          >
            <div className={classes.label} ref={labelRef}>
              {iconLeft}
              <div className={classes.labelText}>
                <div className={classes.inline}>
                  <Typography
                    variant="body1"
                    className={classes.link}
                    dangerouslySetInnerHTML={{
                      __html: label,
                    }}
                  ></Typography>
                  {iconRight}
                </div>
                {info && <Typography className={classes.info}>{info}</Typography>}
              </div>
            </div>
          </Link>
        </NextLink>
      </ListItem>
    )
  }

  return (
    <ListItem
      onClick={handleClick}
      disableGutters
      className={clsx(classes.root, {
        [classes.inverted]: inverted,
        [classes.active]: active,
        [classes.nested]: nested,
        [classes.animatedEntry]: animatedEntry,
        [classes.visible]: enableAnimatedEntry,
        [classes.fixed]: fixed,
        [classes.hasRightIcon]: iconRight,
        [classes.hasLeftIcon]: iconLeft,
        [classes.fade]: fade,
      })}
    >
      <div className={classes.label} ref={labelRef}>
        {iconLeft}
        <div className={classes.labelText}>
          <div className={classes.inline}>
            <Typography component="span" className={classes.link}>
              {label}
            </Typography>
            {iconRight}
          </div>

          {info && <Typography className={classes.info}>{info}</Typography>}
        </div>
      </div>
    </ListItem>
  )
}

export default MenuItem
