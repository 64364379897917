import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      position: 'relative',
    },

    logo: {
      position: 'relative',
      left: -17,
      width: 242,
      height: 54,
      [theme.breakpoints.up('sm')]: {
        left: -19,
        width: 269,
        height: 60,
      },
      [theme.breakpoints.up('lg')]: {
        left: -23,
        width: 322,
        height: 72,
      },
    },
    heading: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 5,
      marginBottom: 5,
    },
    items: {
      padding: 0,
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
        flexDirection: 'row',
        borderWidth: '1px 0px',
        borderColor: theme.palette.beige.main,
        borderStyle: 'solid',
      },

      '& > *:last-child': {
        borderBottomWidth: 1,

        [theme.breakpoints.up('lg')]: {
          borderBottomWidth: 0,
        },
      },
    },
    inverted: {},
    hamburgerIcon: {
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },

    collapsed: {
      height: 'calc(100vh - 64px)',
      maxHeight: 'calc(100vh - 64px)',
      paddingBottom: 80,

      [theme.breakpoints.up('sm')]: {
        height: 'calc(100vh - 70px)',
        maxHeight: 'calc(100vh - 70px)',
        paddingBottom: 40,
      },
    },

    collapseWrap: {
      borderTopWidth: 1,
      borderTopColor: theme.palette.beige.main,
      borderTopStyle: 'solid',

      width: '100%',
      overflow: 'scroll',
      '-webkit-overflow-scrolling': 'touch',
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },

      [theme.breakpoints.up('lg')]: {
        border: 'none',
        overflow: 'initial',
        height: 'auto',
        maxHeight: 'auto',
      },
    },
    collapse: {
      [theme.breakpoints.up('lg')]: {
        height: 'auto !important',
        minHeight: 'auto !important',
        visibility: 'visible !important',
        overflow: 'visible !important',
      },
    },
    backdrop: {
      backgroundColor: 'rgba(0, 0, 0, 0.65)',
    },
    mobileNavOpen: {},

    last: {
      marginRight: 0,
      marginLeft: 'auto',
      display: 'flex',
      border: 0,
      borderBottomWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.beige.main,
      [theme.breakpoints.up('lg')]: {
        borderBottomWidth: 0,
      },
    },

    divider: {
      [theme.breakpoints.up('lg')]: {
        height: 'auto',
        width: 1,
        backgroundColor: theme.palette.beige.main,
      },
    },
  }),
  { name: 'MainMenu' }
)
