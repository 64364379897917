import React from 'react'

import NextLink from 'next/link'

import clsx from 'clsx'

import getYear from 'date-fns/getYear'

import { Typography, Link, List, ListItem, Container, IconButton } from '@material-ui/core'

import { MenuItem, BaseMenuItem, MenuItemType } from '../../../@types/Menu'

import LogoWhite from '../../../public/logo/logo-white.svg'
import LogoBlack from '../../../public/logo/logo-black.svg'

import IconScrollUp from '../../../public/icons/button-scroll-up-footer.svg'

import Socials from '../Socials'

import useStyles from './Footer.styles'
import useMatomoLinkTracking, { MotomoLinkTrackingPosition } from '../../hooks/useMatomoLinkTracking'

type Props = {
  topics: MenuItem[]
  meta: BaseMenuItem[]
  social: BaseMenuItem[]
  blur?: boolean
  invert?: boolean
}

const Footer: React.FC<Props> = ({ topics, meta, social, blur = false, invert }) => {
  const classes = useStyles()

  const [trackedLinkClick] = useMatomoLinkTracking()

  const socialData = social && social?.length > 0 && social[0]

  const handleScrollUp = () => {
    requestAnimationFrame(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    })
  }

  return (
    <div id="footer" className={clsx(classes.root, { [classes.blurContent]: blur, [classes.invert]: invert })}>
      <Container>
        <div className={clsx(classes.header, { [classes.invert]: invert })}>
          <div className={classes.logo}>{invert ? <LogoBlack /> : <LogoWhite />}</div>

          <IconButton
            className={clsx(classes.scrollUp, { [classes.invert]: invert })}
            onClick={handleScrollUp}
            disableRipple={true}
          >
            <IconScrollUp className={classes.scrollUpIcon} />
          </IconButton>
        </div>

        <div className={classes.content}>
          <List className={classes.topics}>
            {socialData && (
              <ListItem key={socialData.label} className={clsx(classes.topic, classes.socials)}>
                <Typography className={clsx(classes.topicLabel, { [classes.invert]: invert })}>
                  {socialData.label}
                </Typography>

                <Socials
                  classes={{
                    root: classes.socialsRoot,
                    item: classes.socialItem,
                  }}
                  data={socialData.items}
                  invert={invert}
                />
              </ListItem>
            )}

            {topics.map((topic, index) => (
              <ListItem key={topic.label} className={clsx(classes.topic, `topic${index}`)}>
                <Typography className={classes.topicLabel}>{topic.label}</Typography>

                <List className={classes.topicItem}>
                  {topic?.items?.map((item: BaseMenuItem) => (
                    <ListItem key={item.label} className={classes.topicItem}>
                      <NextLink href={item?.target || '/#'} passHref>
                        <Link
                          variant="body2"
                          underline="none"
                          className={clsx(classes.topicItemLink, {
                            [classes.invert]: invert,
                            external_link: item.type === MenuItemType.external,
                          })}
                          target={item.type === MenuItemType.external ? '_blank' : undefined}
                          onClick={trackedLinkClick({
                            label: item.label,
                            href: item?.target || '/#',
                            position: MotomoLinkTrackingPosition.FOOTER,
                          })}
                        >
                          {item.label}
                        </Link>
                      </NextLink>
                    </ListItem>
                  ))}
                </List>
              </ListItem>
            ))}
          </List>
        </div>

        <div className={classes.footer}>
          <List className={classes.metas}>
            <ListItem key={'default-footer-meta-key'} className={classes.meta}>
              {/* <Typography className={classes.metaLabel}>{meta.label}</Typography> */}
              <Typography className={clsx(classes.metaLabel, { [classes.invert]: invert })}>
                &copy; {getYear(new Date())} Merz Aesthetics®
              </Typography>

              <List className={classes.metaItem}>
                {meta?.map((item: BaseMenuItem) => (
                  <ListItem key={item.label} className={classes.metaItemLinkWrap}>
                    {item.type != MenuItemType.functional && (
                      <NextLink href={item?.target || '/#'} passHref>
                        <Link
                          variant="body2"
                          underline="none"
                          className={clsx(classes.metaItemLink, { [classes.invert]: invert })}
                          target={item.type === MenuItemType.external ? '_blank' : undefined}
                          onClick={trackedLinkClick({
                            label: item.label,
                            href: item?.target || '/#',
                            position: MotomoLinkTrackingPosition.FOOTER,
                          })}
                        >
                          {item.label}
                        </Link>
                      </NextLink>
                    )}
                    {item.type == MenuItemType.functional && (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: `<a href="javascript:${item.target}" class="${clsx(
                            'MuiLink-underlineNone',
                            classes.metaItemLink,
                            { [classes.invert]: invert }
                          )}" >${item.label}</a>`,
                        }}
                      ></span>
                    )}
                  </ListItem>
                ))}
              </List>
            </ListItem>
          </List>
        </div>
      </Container>
    </div>
  )
}

export default Footer
