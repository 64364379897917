import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'row',
      padding: 0,
      justifyContent: 'center',
      marginTop: theme.spacing(1.5),
      marginBottom: 24,
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(2.25),
      },

      [theme.breakpoints.up('lg')]: {
        marginBottom: 0,
        justifyContent: 'flex-end',
        marginTop: theme.spacing(3.5),
      },
    },
    item: {
      width: 'auto',
      padding: theme.spacing(1.5),
    },
    link: {
      width: 30,
      height: 30,
      textDecoration: 'none',

      [theme.breakpoints.up('sm')]: {
        width: 32,
        height: 32,
      },

      [theme.breakpoints.up('lg')]: {
        width: 40,
        height: 40,
      },
    },
    icon: {
      borderRadius: 4,
      transition: theme.transitions.create('background-color'),

      '& path': {
        transition: theme.transitions.create('stroke'),
      },

      '&:hover': {
        backgroundColor: theme.palette.beige.main,

        '& path': {
          fill: theme.palette.backgroundBlack.main,
        },
      },

      '&$iconInvert': {
        '& path': {
          fill: theme.palette.backgroundBlack.main,
        },

        '&:hover': {
          backgroundColor: theme.palette.beige.main,

          '& path': {
            fill: theme.palette.common.black,
          },
        },
      },
    },

    iconInvert: {},
  }),
  { name: 'Socials' }
)
