import React, { useEffect } from 'react'
import {
  Box,
  Button,
  SwipeableDrawer,
  Typography,
  InputBase,
  styled,
  useTheme,
  useMediaQuery,
  Fade,
} from '@material-ui/core'
import clsx from 'clsx'
import { useInView } from 'react-hook-inview'
import { useForm, Controller } from 'react-hook-form'
import { useRouter } from 'next/router'

import FloatingIcon from '../../../public/icons/icon-doctor-black.svg'

import CloseIcon from '../../../public/icons/icon-close.svg'
import CloseArrow from '../../../public/icons/icon-arrow.svg'

import RotatingDocFinderIcon from '../RotatingDocFinderIcon'

import useStyles from './FloatingActionButton.styles'
import useMatomoDocSearchTracking from '../../hooks/useMatomoDocSearchTracking'

interface Props {
  window?: () => Window
  invertColors?: boolean
}

const StyledBox = styled(Box)(() => ({
  backgroundColor: '#E2E1DD',
}))

const FloatingActionButton: React.FC<Props> = (props) => {
  const theme = useTheme()
  const classes = useStyles()

  const { trackDocSearch } = useMatomoDocSearchTracking()

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  const router = useRouter()

  const { handleSubmit, control } = useForm()

  const onSubmit = ({ searchTerm }: { searchTerm: string }) => {
    trackDocSearch(searchTerm)

    router.push({
      pathname: '/arzt-suche/',
      query: {
        search: searchTerm,
      },
      hash: searchTerm ? 'map' : 'map-filters',
    })
  }

  const { window } = props
  const [open, setOpen] = React.useState(false)

  const [footerRef, isFooterVisible] = useInView({
    threshold: 0.15,
  })
  const [homeStageRef, isHomeStageVisible] = useInView({
    threshold: 0.8,
  })

  useEffect(() => {
    const element = document.getElementById('footer')
    footerRef(element)
    const homeStateElement = document.getElementById('home_stage_content')
    homeStageRef(homeStateElement)
  }, [])

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen)
  }

  // This is used only for the example
  const container = window !== undefined ? () => window().document.body : undefined

  const invertIconColors = props?.invertColors ? !isFooterVisible : isFooterVisible

  return (
    <Fade in={!isHomeStageVisible}>
      <div className={classes.root}>
        <Button
          className={clsx(classes.floatingBtn, { [classes.invert]: invertIconColors })}
          onClick={toggleDrawer(true)}
          onMouseEnter={isDesktop ? toggleDrawer(true) : undefined}
        >
          <div style={open ? { display: 'none' } : { display: 'block' }}>
            <RotatingDocFinderIcon />
          </div>
        </Button>

        <SwipeableDrawer
          className={clsx(classes.drawerWrap, `${open ? classes.show : classes.hide} `)}
          BackdropProps={{ invisible: true }}
          container={container}
          anchor={isDesktop ? 'right' : 'bottom'}
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          swipeAreaWidth={0}
          disableSwipeToOpen={false}
          classes={{ paper: classes.drawerPaper }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Box className={classes.wrap} onMouseLeave={isDesktop ? toggleDrawer(false) : undefined}>
            <Button className={classes.closeBtnWrap} onClick={toggleDrawer(false)}>
              <CloseIcon className={classes.closeIcon} />
            </Button>

            <form
              data-matomo-name="floating-action-search"
              onSubmit={handleSubmit(onSubmit)}
              className={classes.container}
            >
              <div className={classes.titleWrap}>
                <FloatingIcon className={classes.insideIcon} />

                <Typography className={classes.title}>Finde deinen Arzt</Typography>
              </div>

              <div className={classes.btnWrap}>
                <Controller
                  name="searchTerm"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <InputBase
                      value={field.value}
                      onChange={field.onChange}
                      placeholder="PLZ oder Ort"
                      autoFocus={false}
                      className={classes.input}
                    />
                  )}
                />
                <Button type="submit" className={classes.closeArrowWrap}>
                  <CloseArrow className={classes.closeArrow} />
                </Button>

                <Button type="submit" variant="outlined" className={classes.searchButton}>
                  Suche starten
                </Button>
              </div>
            </form>
          </Box>
        </SwipeableDrawer>
      </div>
    </Fade>
  )
}

export default FloatingActionButton
