import { makeStyles } from '@material-ui/core'

export default makeStyles(
  () => ({
    root: {
      width: '100%',
      height: '100%',
      position: 'relative',
      top: 4,
      '& #Text': {
        transformOrigin: 'center center',
      },
    },
  }),
  { name: 'RotatingDocFinderIcon' }
)
