import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    dialogScrollPaper: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      position: 'relative',
    },
    paper: {
      width: 303,
      minHeight: 352,
      padding: '34px 12px 30px 12px',
      borderRadius: 0,
      marginTop: 100,

      [theme.breakpoints.up('sm')]: {
        width: '100%',
        maxWidth: 600,
        minHeight: 276,
      },
      [theme.breakpoints.up('md')]: {
        width: '100%',
        maxWidth: 692,
        minHeight: 304,
        marginTop: 200,
      },
    },
    title: {
      fontFamily: 'ProximaNova-Regular',
      fontSize: '24px',
      lineHeight: '32px',
      letterSpacing: '1px',
      textAlign: 'center',
      color: theme.palette.common.black,
      marginBottom: 14,

      [theme.breakpoints.up('sm')]: {
        fontSize: '28px',
        lineHeight: '34px',
      },

      [theme.breakpoints.up('md')]: {
        fontSize: '36px',
        lineHeight: '48px',
      },
    },
    copy: {
      fontFamily: 'ProximaNova-Regular',
      fontSize: '14px',
      lineHeight: '22px',
      letterSpacing: '0.5px',
      textAlign: 'center',
      color: theme.palette.common.black,
      marginBottom: 33,

      [theme.breakpoints.up('sm')]: {
        marginBottom: 38,
      },

      [theme.breakpoints.up('md')]: {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },

    copyBreak: {
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    actions: {
      marginLeft: 23,
      marginRight: 23,
      display: 'flex',
      flexDirection: 'column',
      gap: 16,

      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        justifyContent: 'center',
      },
    },

    action: {
      [theme.breakpoints.up('sm')]: {
        maxWidth: 220,
      },
    },
  }),
  { name: 'WelcomeDialog' }
)
