import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flexGrow: 1,
      alignSelf: 'stretch',
    },
    inverted: {
      backgroundColor: theme.palette.backgroundBlack.main,
    },
    nonScrollable: { overflow: 'hidden' },
    content: {
      filter: 'none',
      transition: 'filter .5s linear',
      '-webkit-transition': 'filter .5s linear',
      '-moz-transition': 'filter .5s linear',
      '-o-transition': 'filter .5s linear',
      '-ms-transition': 'filter .5s linear',
      '&$navOpen': {
        filter: 'blur(4px)',
      },
    },
    mainMenuWrapper: {
      width: '100%',
      margin: '0 auto',
      zIndex: 2,
      '&$navOpen': {
        height: '100%',
      },
    },
    menuScrollWrap: {
      top: 0,
      left: 'auto',
      right: 0,
      position: 'fixed',
      boxShadow: 'none',
      backgroundColor: '#fff',
    },
    menuBlack: {
      backgroundColor: theme.palette.backgroundBlack.main,
    },
    menuWhite: {
      backgroundColor: theme.palette.common.white,
    },
    menuScrollWrapNoBackground: {
      backgroundColor: 'transparent',
    },
    navOpen: {},
  }),
  { name: 'Layout' }
)
