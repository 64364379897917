export enum MenuItemType {
  internal = 'internal',
  anchor = 'anchor',
  download = 'download',
  external = 'external',
  functional = 'functional',
}

export interface BaseMenuItem {
  label: string
  type?: MenuItemType
  target?: string
  info?: string
  items?: BaseMenuItem[]
  target_external?: string
}

export interface MenuItem extends BaseMenuItem {
  items?: BaseMenuItem[]
}
