import { load } from 'cheerio'

const stripHtml = (html: string): string => {
  try {
    const input = decodeURIComponent(html)

    if (document) {
      const tmp = document.createElement('div')
      tmp.innerHTML = input
      return tmp.textContent || tmp.innerText || ''
    }

    const $ = load(input)
    return $.text()
  } catch (_) {
    return ''
  }
}

export default stripHtml
