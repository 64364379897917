import React, { useRef, useEffect } from 'react'

import useStyles from './RotatingDocFinderIcon.styles'

const RotatingDocFinderIcon: React.FC = () => {
  const textRef = useRef<SVGGElement | null>(null)
  const classes = useStyles()

  const handleScroll = () => {
    if (textRef?.current?.style?.transform) {
      textRef.current.style.transform = 'rotate(' + window.pageYOffset / 2 + 'deg)'
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true)

    return () => {
      window.removeEventListener('scroll', handleScroll, true)
    }
  }, [])

  return (
    <svg className={classes.root} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108 108">
      <defs>
        <mask id="mask" x="18.23" y="18.1" width="72" height="72" maskUnits="userSpaceOnUse">
          <g id="b">
            <path id="a" fill="#fff" fillRule="evenodd" d="M18.23,90.1h72v-72h-72Z" />
          </g>
        </mask>
      </defs>
      <g id="Doc_Finder_Floating_Element" data-name="Doc Finder Floating Element">
        <g id="Circle">
          <circle fill="#E2E1DD" cx="54" cy="54" r="36" />
        </g>
        <g id="Icon">
          <path fillRule="evenodd" d="M53.71,36.9h1.4V29.68h-1.4Z" />
          <path
            fillRule="evenodd"
            d="M54.35,65a10.24,10.24,0,0,1-8.54-4.62,8.11,8.11,0,0,1-.89-1.67,10.51,10.51,0,0,1-.68-2.26,3.79,3.79,0,0,1-2.1-1.09A3.86,3.86,0,0,1,41,52.67V50.83a2.28,2.28,0,0,1,2.26-2.28,2.49,2.49,0,0,1,.85.18V46.62h1.4v4.21a.7.7,0,1,1-1.4,0h0a.87.87,0,0,0-.87-.87.84.84,0,0,0-.83.86v1.85A2.45,2.45,0,0,0,44.13,55c0-.14,0-.28,0-.42a.65.65,0,0,1,.72-.66.7.7,0,0,1,.68.7v1.15a8.6,8.6,0,0,0,.68,2.42,2.73,2.73,0,0,0,.14.33A5.69,5.69,0,0,0,47,59.62a8.38,8.38,0,0,0,1.41,1.67,8.77,8.77,0,0,0,11.91,0,9.62,9.62,0,0,0,2-2.62l.18-.4a9.19,9.19,0,0,0,.71-2.84v-.75a.7.7,0,0,1,1.4,0v.37a2.43,2.43,0,0,0,1.71-2.32V50.82A.84.84,0,0,0,66,50.2a.76.76,0,0,0-.42-.23s0,0-.1,0a1,1,0,0,0-.68.26.86.86,0,0,0-.25.61l-1.4,0a2.26,2.26,0,0,1,2.22-2.29h0a1.54,1.54,0,0,1,.43,0A2.28,2.28,0,0,1,67,49.2a2.33,2.33,0,0,1,.66,1.62v1.89a3.83,3.83,0,0,1-3.24,3.81,10.51,10.51,0,0,1-.68,2.28c-.07.17-.15.35-.23.52a11.51,11.51,0,0,1-2.28,3A10.09,10.09,0,0,1,54.35,65"
          />
          <path
            fillRule="evenodd"
            d="M54.41,36.26A10.3,10.3,0,0,1,64.67,46.62v2.1a1.86,1.86,0,0,1,.46-.13V41.44a11,11,0,0,0-10.73-11h-.16a10.86,10.86,0,0,0-7.68,3.22,11.27,11.27,0,0,0-1.85,2.49,11.48,11.48,0,0,0-1.32,5.29v7.15a2.16,2.16,0,0,1,.76.17v-2.1a10.3,10.3,0,0,1,3-7.35,10.13,10.13,0,0,1,7.24-3M64,51.53h0a.7.7,0,0,1-.7-.7V46.61a8.86,8.86,0,1,0-17.71,0v4.22a.7.7,0,1,1-1.4,0,.89.89,0,0,0-.87-.89H43c-.11.07-.1.09-.22.09a.8.8,0,0,1-.81-.7V41.38a13.07,13.07,0,0,1,1.49-5.94,12.6,12.6,0,0,1,2.09-2.81A12.26,12.26,0,0,1,54.24,29h.18A12.4,12.4,0,0,1,66.54,41.45v7.81a.69.69,0,0,1-.24.52.66.66,0,0,1-.55.17h-.16a1,1,0,0,0-.66.23l-.14.17-.09.19a1.53,1.53,0,0,0,0,.3.71.71,0,0,1-.7.69M75.25,70.29h-1.4a10.7,10.7,0,0,0-9.34-10.64l-.15,0c-.44-.07-.87-.11-1.31-.14l0-1.4a9.92,9.92,0,0,1,1.54.15A12.14,12.14,0,0,1,75.25,70.29M33.13,70a12.2,12.2,0,0,1,1.39-5.71A11.87,11.87,0,0,1,41,58.52a3.63,3.63,0,0,1,.88-.28,9.65,9.65,0,0,1,2.63-.44h1v1.41h-1a8.4,8.4,0,0,0-2.3.39,2.84,2.84,0,0,0-.68.22,10.52,10.52,0,0,0-5.82,5.07,10.83,10.83,0,0,0-1.23,5ZM43.22,71a2.4,2.4,0,0,0,0,4.8,2.45,2.45,0,0,0,1.7-.67,2.37,2.37,0,0,0,.72-1.69v0a2.33,2.33,0,0,0-.7-1.67,2.38,2.38,0,0,0-1.7-.72m0,6.21h0a3.81,3.81,0,0,1,0-7.61A3.82,3.82,0,0,1,47,73.48v0a3.81,3.81,0,0,1-3.81,3.75m27.17,1.68H68.56V77.52h1.13v-1c0-3.18-1.61-5.87-3.52-5.87s-3.51,2.69-3.51,5.87v1H63.8v1.41H62a.71.71,0,0,1-.71-.71v-1.7c0-4,2.21-7.28,4.92-7.28s4.92,3.27,4.92,7.28v1.7a.7.7,0,0,1-.7.71"
          />
          <path
            fillRule="evenodd"
            d="M66.83,70h-1.4v-6.9a6.05,6.05,0,0,0-1.21-3.74l1.17-.79a7.46,7.46,0,0,1,1.44,4.53ZM43.92,65.91h-1.4V63.1a10.24,10.24,0,0,1,1.51-5l1.2.74a8.86,8.86,0,0,0-1.31,4.24v2.78Z"
          />
          <g mask="url(#mask)">
            <path fillRule="evenodd" d="M42.52,70.34h1.4V65.1h-1.4Z" />
          </g>
        </g>
        <g ref={(el) => (textRef.current = el)} id="Text" style={{ transform: 'rotate(100deg)' }}>
          <g id="TEXT-2" data-name="TEXT">
            <g id="Text-3" data-name="Text">
              <path d="M100.44,78.89l-2.2,3.78-.64-.38,1.79-3.05-1.86-1.09-1.75,3-.64-.37,1.75-3-2.62-1.53.42-.72Z" />
              <path d="M97,84.38l-.48.67-5.42-3.88.48-.67Z" />
              <path d="M93.48,86.93l-4-3.6.55-.62,5,4.46-.57.64L88,87.14l3.91,3.52-.55.61-5-4.45.53-.6Z" />
              <path d="M89.22,93.26,87.44,94.7a3.34,3.34,0,1,1-4.19-5.2L85,88.07Zm-5.5-3.18a2.6,2.6,0,0,0,3.26,4l1.13-.91-3.26-4Z" />
              <path d="M82.66,98.23l-3.76,2.22-.37-.63,3-1.81-1.09-1.85-3,1.77-.38-.63,3-1.77-1.17-2-3.05,1.8-.38-.63,3.76-2.23Z" />
              <path d="M70.3,104.15l-2.2.59a3.34,3.34,0,1,1-1.74-6.44l2.2-.59ZM66.56,99a2.59,2.59,0,0,0,1.35,5l1.4-.38-1.35-5Z" />
              <path d="M62.26,106.05l-4.34.52-.08-.74,3.51-.41-.25-2.14-3.45.41-.08-.73,3.44-.41-.27-2.28-3.51.41-.09-.73,4.34-.52Z" />
              <path d="M55.65,106.65l-.83,0L54.67,100l.83,0Z" />
              <path d="M51.49,105.35,52,100l.83.07-.58,6.64-.85-.08L48,101.05l-.46,5.25-.82-.07.58-6.64.79.07Z" />
              <path d="M43.91,105.73l-4.25-1,.17-.72,3.44.82.5-2.1-3.38-.8.17-.72,3.38.8.53-2.23L41,99l.17-.72,4.25,1Z" />
              <path d="M37,102.49l2-5,.77.3L37.24,104l-.79-.31-1.64-6.32-1.93,4.91-.77-.31,2.44-6.2.75.29Z" />
              <path d="M23.59,90.5l2.67,2.07,1.37-.82.73.57L22.16,96l-.82-.64,2-6.92L24,89Zm2,2.5-2.29-1.76L22.2,95.05Z" />
              <path d="M19.08,87.87l.92.95L21.91,87l.57.6-4.79,4.63-1.86-1.93a1.93,1.93,0,0,1,0-2.89,1.84,1.84,0,0,1,2.6-.11l.7-3.18.68.71Zm-2.65,2,1.21,1.26,1.84-1.77-1.22-1.26a1.27,1.27,0,1,0-1.83,1.77Z" />
              <path d="M17.78,83.57l-6.44.06,2.21,3-.6.44-2.83-3.88.55-.41,6.45-.06-2.26-3.09.6-.43,2.88,3.94Z" />
              <path d="M8.64,79.27l1,1.85L9,81.48,6.58,77l.65-.36,1,1.86,5.2-2.84.4.73Z" />
              <path d="M7.66,28.89l2.22-3.76.64.37-1.8,3.05,1.86,1.1,1.76-3L13,27l-1.77,3,2.62,1.55-.42.71Z" />
              <path d="M11.12,23.42l.49-.67L17,26.66l-.49.67Z" />
              <path d="M14.66,20.89l4,3.61-.56.62-4.94-4.48.57-.63,6.48.7-3.9-3.54.55-.62L21.79,21l-.53.59Z" />
              <path d="M19,14.57l1.79-1.42a3.33,3.33,0,1,1,4.16,5.21l-1.78,1.42Zm5.49,3.21a2.44,2.44,0,0,0,.38-3.62,2.42,2.42,0,0,0-3.62-.43l-1.13.9,3.24,4.06Z" />
              <path d="M25.54,9.63l3.78-2.21.37.64L26.63,9.85l1.09,1.86,3-1.76.37.64-3,1.75,1.16,2,3.06-1.79.37.64-3.77,2.21Z" />
              <path d="M37.93,3.77l2.21-.58A3.24,3.24,0,0,1,44.3,5.54a3.24,3.24,0,0,1-2.45,4.1l-2.21.58Zm3.73,5.15a2.46,2.46,0,0,0,1.81-3.16A2.45,2.45,0,0,0,40.33,3.9l-1.41.37,1.34,5Z" />
              <path d="M45.93,1.91l4.34-.5.09.73-3.52.41.25,2.13,3.45-.39.08.73-3.45.4.27,2.29L51,7.3,51,8l-4.34.51Z" />
              <path d="M52.59,1.34h.83L53.54,8h-.83Z" />
              <path d="M56.75,2.66,56.25,8l-.82-.07L56,1.3l.85.07L60.23,7l.48-5.25.83.07-.61,6.65-.8-.08Z" />
              <path d="M64.32,2.32l4.25,1-.18.72L65,3.23l-.5,2.09,3.37.82-.17.72L64.28,6l-.55,2.24,3.44.83L67,9.83l-4.25-1Z" />
              <path d="M71.29,5.59l-2,5-.77-.3L71,4.08l.79.32,1.61,6.32,1.95-4.9.77.31-2.47,6.19L72.91,12Z" />
              <path d="M84.58,17.64l-2.67-2.08-1.37.8-.73-.56L86,12.19l.81.64-2,6.9-.72-.56Zm-2-2.52,2.27,1.78L86,13.09Z" />
              <path d="M89.08,20.29l-.92-1-1.91,1.83-.58-.6L90.49,16l1.85,1.93a1.94,1.94,0,0,1,0,2.9,1.83,1.83,0,0,1-2.59.09L89,24.05l-.68-.7Zm2.66-2-1.21-1.26-1.85,1.76,1.21,1.26a1.28,1.28,0,1,0,1.85-1.76Z" />
              <path d="M90.36,24.6l6.44,0-2.2-3,.6-.43L98,25l-.56.4-6.44,0,2.24,3.1-.6.43L89.8,25Z" />
              <path d="M99.48,29l-1-1.86.65-.35,2.41,4.45-.66.35-1-1.86L94.66,32.5l-.4-.74Z" />
            </g>
          </g>
          <g id="Contour">
            <path
              fill="#E2E1DD"
              d="M5.83,63.8a.75.75,0,0,1-.73-.61,50.18,50.18,0,0,1-.17-17.43A.75.75,0,0,1,6.41,46a48.61,48.61,0,0,0-.66,8,49.17,49.17,0,0,0,.82,8.92.75.75,0,0,1-.6.87Z"
            />
            <path
              fill="#E2E1DD"
              d="M102.19,63.66h-.13a.76.76,0,0,1-.61-.87,48.64,48.64,0,0,0,.15-16.71.75.75,0,1,1,1.48-.25,50.31,50.31,0,0,1-.15,17.23A.75.75,0,0,1,102.19,63.66Z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default RotatingDocFinderIcon
