import { PageSettings } from './PageSettings'
import { AnchorNav } from './Section/AnchorNav'

export enum PageType {
  Page = 'page',
  Blog = 'blog',
  BlogOverview = 'blogOverview',
  Home = 'home',
  Campaign = 'campaign',
}
export interface PageData {
  title: string
  slug: string
  link: string
  type: PageType
  meta: PageMetaData
  sections: Section[]
  'has-sticky_doc_search'?: boolean
  tabfilter: TabFilter
  anchor_nav?: AnchorNav | null
  canonical?: string | null
}

export interface PageMetaData {
  tags: any
  schema?: string
}

export interface Section {
  type: string
  fields: any
}

export interface PageProps {
  content: PageData
  settings: PageSettings
}

export type TabFilter = {
  has_filter: boolean
  filter: TabFilterFilter[]
  color_overwrite?: {
    color?: string
  }
}

export type TabFilterFilter = {
  title: string
  category: string | null
  id: string
}
