import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {
      padding: 0,
      cursor: 'pointer',
      color: theme.palette.common.black,
      flexDirection: 'column',
      alignItems: 'flex-start',
      borderWidth: '1px 0px 0px 0px',
      borderColor: theme.palette.beige.main,
      borderStyle: 'solid',
      transition: theme.transitions.create(['opacity', 'left'], {
        duration: theme.transitions.duration.standard,
      }),

      [theme.breakpoints.up('lg')]: {
        marginRight: theme.spacing(3),
        width: 'auto',
        borderWidth: 0,

        '& $link:hover:after, &$active $link:after': {
          height: 4,
          background: theme.palette.beige.main,
        },
      },

      [theme.breakpoints.up('xl')]: {
        marginRight: theme.spacing(4),
      },

      '&$animatedEntry': {
        position: 'relative',
        left: 20,
        opacity: 0,
        transition: 'opacity, left .5s linear',
        '-webkit-transition': 'opacity, left .5s linear',
        '-moz-transition': 'opacity, left .5s linear',
        '-o-transition': 'opacity, left .5s linear',
        '-ms-transition': 'opacity, left .5s linear',
      },

      '&$visible': {
        opacity: 1,
        left: 0,
        transition: 'opacity .5s linear',
        '-webkit-transition': 'opacity .5s linear',
        '-moz-transition': 'opacity .5s linear',
        '-o-transition': 'opacity .5s linear',
        '-ms-transition': 'opacity .5s linear',
      },

      '&$hasRightIcon $link:after': {
        width: 'calc(100% - 12px)',
      },
    },
    inverted: {
      '& $link, & $info': {
        color: theme.palette.common.white,
      },
    },
    active: {},
    label: {
      width: '100%',
      display: 'inline-flex',
      alignItems: 'center',
      [theme.breakpoints.up('lg')]: {
        width: 'auto',
      },
    },
    labelText: {
      display: 'inline-flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        width: 'auto',
      },
    },
    nested: {
      borderWidth: 0,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),

      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(6),

        '&:last-child': {
          marginBottom: theme.spacing(3),
        },
      },

      [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(6.75),

        '&:hover .nested-icon-right': {
          opacity: 1,
          marginLeft: theme.spacing(4),
        },
      },

      '& $link': {
        fontSize: 30,
        lineHeight: 1.33,
        letterSpacing: 1,
        fontFamily: 'ProximaNova-Semibold',
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(1),

        [theme.breakpoints.up('sm')]: {
          fontSize: 38,
          lineHeight: 1.21,
          paddingBottom: theme.spacing(1.5),
        },

        [theme.breakpoints.up('lg')]: {
          fontSize: 44,
          lineHeight: 1.18,
          paddingTop: theme.spacing(0.5),
          paddingBottom: theme.spacing(1),
        },
      },

      '& $link:after': {
        display: 'none',
      },

      '& $label': {
        flexDirection: 'column',
        alignItems: 'flex-start',
        wordBreak: 'break-word',

        [theme.breakpoints.up('sm')]: {
          wordBreak: 'initial',
          flexDirection: 'row',
        },

        [theme.breakpoints.up('lg')]: {
          alignItems: 'center',
        },
      },
    },
    link: {
      color: theme.palette.common.black,
      lineHeight: 1.5,
      fontSize: 16,
      letterSpacing: 0.5,
      paddingTop: 22,
      paddingBottom: 22,

      '&:after': {
        position: 'absolute',
        bottom: 0,
        content: "''",
        display: 'block',
        margin: 'auto',
        height: 0,
        width: '100%',
        background: 'transparent',
        transition: 'height .15s ease-in-out, background-color .15s ease-in-out',
      },

      [theme.breakpoints.up('lg')]: {
        paddingTop: 14,
        paddingBottom: 14,
      },
    },
    info: {
      fontWeight: 'normal',
      lineHeight: 1.25,
      letterSpacing: 0.5,

      [theme.breakpoints.up('lg')]: {
        fontSize: 16,
        lineHeight: 1.5,
      },
    },
    visible: {},
    animatedEntry: {},
    fixed: {
      [theme.breakpoints.up('lg')]: {
        marginLeft: 'auto',
        marginRight: 0,
        '& $labelText': {
          position: 'relative',
        },
        '& $label': {
          paddingLeft: 40,
        },
      },
    },
    hasRightIcon: {},
    hasLeftIcon: {},
    inline: {
      display: 'inline-flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      [theme.breakpoints.up('lg')]: {
        width: 'unset',
        justifyContent: 'unset',
      },
    },
    fade: {
      opacity: 0.4,

      '&:hover': {
        opacity: 1,
      },
    },
  }),
  { name: 'MenuItem' }
)
