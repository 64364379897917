import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    button: {
      minWidth: 158,
      borderRadius: 100,
      paddingTop: 11,
      paddingBottom: 11,
      paddingLeft: 20,
      paddingRight: 20,
      textTransform: 'none',
      alignSelf: 'flex-start',
      borderWidth: 1,
      borderStyle: 'solid',
      fontFamily: 'ProximaNova-Regular',
      fontSize: 14,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.29,
      letterSpacing: 0.5,
      '&:hover': {
        backgroundColor: `${theme.palette.beige.main} !important`,
        borderColor: `${theme.palette.beige.main} !important`,
        color: theme.palette.common.black,
      },
      '&[disabled]': {
        opacity: 0.4,
        color: theme.palette.common.white,
      },
    },

    primaryButton: {
      borderColor: theme.palette.common.black,
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },

    secondaryButton: {
      borderColor: theme.palette.common.black,
      backgroundColor: 'transparent',
      color: theme.palette.common.black,
    },
  }),
  { name: 'BaseButton' }
)
